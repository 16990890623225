import styled from "@emotion/styled"
import { COLOR, FONT_FAMILY } from "../../design"

export const ButtonWrapper = styled.button`
  font-family: ${FONT_FAMILY.HEADING};
  background: ${COLOR.BLUE100};
  color: ${COLOR.YELLOW900};
  text-transform: uppercase;
  border-radius: 4px;
  font-size: 16px;
  line-height: 1.8;
  padding: 16px;
  border: 2px solid ${COLOR.BLUE100};
  cursor: pointer;

  &.is-block {
    width: 100%;
  }

  &:hover {
    background: ${COLOR.YELLOW900};
    color: ${COLOR.BLUE100};
  }

  &:active {
    background: ${COLOR.BLUE300};
    color: ${COLOR.YELLOW700};
  }
`
