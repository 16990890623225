import styled from "@emotion/styled"
import { COLOR, getTypo, ETypo, SPACE } from "../../design"
import Warning from "../../assets/warning-icon.svg"

export const StyledInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const Label = styled.label`
  color: ${COLOR.GREY100};
  ${getTypo(ETypo.FORM_LABEL)};
  font-weight: 500;
`

export const Error = styled.div`
  display: flex;
  align-items: center;
  color: ${COLOR.DANGER};
  ${getTypo(ETypo.META)};
  font-weight: 600;
  line-height: 1.4;
  margin-top: ${SPACE.SPACE8};
`

export const WarningIcon = styled(Warning)`
  width: 16px;
  margin-right: ${SPACE.SPACE8};
`
