import React from "react"
import { graphql } from "gatsby"
import Header from "../components/header"
import HeadLine from "../components/headline"
import GlobalWrapper from "../templates/global"
import Container from "../components/container"
import JoinForm from "../components/join-form"

export default ({ data }) => {
  return (
    <GlobalWrapper>
      <Header />

      <Container>
        <HeadLine>
          We’re a group of UX enthusiasts and professionals creating a community for human-centered thinkers to
          collaborate, critique, and share their ideas.
        </HeadLine>

        <JoinForm />
      </Container>
    </GlobalWrapper>
  )
}

export const pageQuery = graphql`
  query {
    allMdx(sort: { fields: frontmatter___date, order: DESC }) {
      nodes {
        frontmatter {
          title
          path
          date
        }
      }
    }
  }
`
