import * as React from "react"
import { Field, Formik } from "formik"
import * as Yup from "yup"
import * as S from "./join-form.style"
import Input from "../input"
import Select from "../select"

function encode(data: { "form-name": string; [key: string]: string }): string {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const roles = [
  { name: "Designer", inviteLink: "//discord.gg/NAzM6BA" },
  { name: "Hobbyist", inviteLink: "//discord.gg/FYWHYyP" },
  { name: "Student", inviteLink: "//discord.gg/dbuyCW2" },
]

export interface IJoinFormInitialValues {
  "discordTag": string
  "email": string
  "role": string
  "bot-field": ""
}

const JoinForm: React.FC = () => {
  return (
    <Formik<IJoinFormInitialValues>
      initialValues={{
        "discordTag": "",
        "email": "",
        "role": "",
        "bot-field": "",
      }}
      validationSchema={Yup.object().shape({
        discordTag: Yup.string()
          .matches(/.*#[0-9]{4}/, "Enter a valid Discord tag (user#0000)")
          .required("Required"),
        email: Yup.string().email("Invalid email").required("Required"),
        role: Yup.string()
          .oneOf(roles.map((role) => role.name.toLowerCase()))
          .required("Required"),
      })}
      onSubmit={(values) => {
        fetch("/?no-cache=1", {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: encode({
            "form-name": "join",
            ...values,
          }),
        }).then(() => {
          const role = roles.find((role) => role.name.toLowerCase() === values.role)
          window.open(role.inviteLink, "_blank")
        })
      }}
    >
      {() => (
        <S.StyledJoinForm method="post" data-netlify="true" data-netlify-honeypot="bot-field">
          <S.Header>Join our community</S.Header>
          <S.Row>
            <S.Column>
              <Field name="discordTag" label="Discord ID" placeholder="user#0000" component={Input} />
            </S.Column>
            <S.Column>
              <Field name="email" label="Email" placeholder="name@domain.com" component={Input} />
            </S.Column>
            <S.Column>
              <Field
                name="role"
                label="Role"
                placeholder="Designer"
                options={roles.map((role) => {
                  return { value: role.name.toLowerCase(), label: role.name }
                })}
                component={Select}
              />
            </S.Column>
            <div hidden>
              <Field name="bot-field" label="Do not fill" component={Input} />
            </div>
            <S.ButtonColumn>
              <S.StyledButton block type="submit">
                Join
              </S.StyledButton>
            </S.ButtonColumn>
          </S.Row>
        </S.StyledJoinForm>
      )}
    </Formik>
  )
}

export default JoinForm
