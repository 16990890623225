import * as React from "react"
import ReactSelect, { Props as ReactSelectProps } from "react-select"
import { FieldProps } from "formik"
import { COLOR, SPACE, getRawTypo, ETypo } from "../../design"
import InputWrapper from "../input-wrapper"
import { IJoinFormInitialValues } from "../join-form/join-form.component"

interface ISelect extends ReactSelectProps, FieldProps<IJoinFormInitialValues> {
  label: string
  placeholder: string
}

const Select: React.FC<ISelect> = ({ field, form, ...props }) => {
  const isTouched = form.touched[field.name]
  const isSubmitted = form.submitCount > 0
  const error = isSubmitted && isTouched && form.errors[field.name]

  return (
    <InputWrapper id={field.name} label={props.label} error={error}>
      <ReactSelect
        inputId={field.name}
        placeholder={props.placeholder}
        // @ts-ignore
        options={props.options}
        menuPlacement="auto"
        // menuIsOpen={true}
        onChange={(fieldValue) => {
          // @ts-ignore
          form.setFieldValue(field.name, fieldValue.value)
        }}
        styles={{
          control: (provided, state) => {
            const color = (() => {
              if (error) return COLOR.DANGER
              if (state.isFocused) return COLOR.BLUE500
              return COLOR.GREY300
            })()

            return {
              ...provided,
              ...getRawTypo(ETypo.FORM_INPUT),
              padding: "13px",
              border: "1px solid",
              borderColor: `${color} !important`,
              boxShadow: state.isFocused || error ? `0 0 0 3px ${color}` : "none",
              transition: "none"
            }
          },
          valueContainer: (provided) => ({
            ...provided,
            paddingTop: 0,
            paddingBottom: 0,
          }),
          placeholder: (provided) => ({
            ...provided,
            color: "#bbb"
          }),
          menu: (provided, state) => ({
            ...provided,
            marginTop: "auto",
            border: "1px solid",
            borderColor: state.isFocused ? COLOR.BLUE500 : COLOR.GREY300,
            padding: `${SPACE.SPACE16} 0`,
          }),
          indicatorSeparator: () => ({
            display: "none",
          }),
          dropdownIndicator: (provided) => ({
            ...provided,
            color: COLOR.GREY300,
          }),
          option: (provided, state) => ({
            ...provided,
            ...getRawTypo(ETypo.FORM_INPUT),
            "background": state.isSelected || state.isFocused ? COLOR.BLUE300 : "none",
            "color": state.isSelected || state.isFocused ? "#fff" : provided.color,
            "&:hover": {
              background: COLOR.BLUE300,
              color: "#fff",
            },
          }),
        }}
      />
    </InputWrapper>
  )
}

export default Select
