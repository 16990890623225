import * as React from "react"
import cx from "classnames"
import * as S from "./input-wrapper.style"

interface IInputWrapper {
  id: string
  label: string
  error: any
}

const InputWrapper: React.FC<IInputWrapper> = (props) => {
  return (
    <S.StyledInputWrapper className={cx({ "is-error": props.error })}>
      <S.Label htmlFor={props.id}>{props.label}</S.Label>
      {props.children}
      {props.error && (
        <S.Error>
          <S.WarningIcon />
          {props.error}
        </S.Error>
      )}
    </S.StyledInputWrapper>
  )
}

export default InputWrapper
