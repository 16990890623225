import * as React from "react"
import cx from "classnames"
import * as S from "./button.style"

interface IButton extends React.ComponentProps<"button"> {
  block?: boolean
}

const Button: React.FC<IButton> = (props) => {
  return (
    <S.ButtonWrapper {...props} className={cx(props.className, { ["is-block"]: props.block })}>
      {props.children}
    </S.ButtonWrapper>
  )
}

export default Button
