import styled from "@emotion/styled"
import { COLOR, getTypo, ETypo } from "../../design"

export const StyledInput = styled.input`
  padding: 17px;
  border-radius: 4px;
  border: 1px solid ${COLOR.GREY300};
  ${getTypo(ETypo.FORM_INPUT)};

  &::placeholder {
    color: #bbb;
  };

  .is-error & {
    box-shadow: 0 0 0 3px ${COLOR.DANGER} !important;
    border-color: ${COLOR.DANGER} !important;
  }

  &:hover {
    border-color: ${COLOR.GREY700};
  }

  &:focus {
    box-shadow: 0 0 0 3px ${COLOR.BLUE500};
    border-color: ${COLOR.BLUE500};
    outline: none;
  }
`
