import * as React from "react"
import { FieldProps } from "formik"
import InputWrapper from "../input-wrapper"
import * as S from "./input.style"

interface IInput extends FieldProps {
  label: string
  placeholder: string
}

const Input: React.FC<IInput> = ({ field, form, ...props }) => {
  const isSubmitted = form.submitCount > 0
  const isTouched = form.touched[field.name]
  const error = isSubmitted && isTouched && form.errors[field.name]

  return (
    <InputWrapper id={field.name} label={props.label} error={error}>
      <S.StyledInput {...field} id={field.name} placeholder={props.placeholder} />
    </InputWrapper>
  )
}

export default Input
