import styled from "@emotion/styled"
import { transparentize, stripUnit } from "polished"
import { Form } from "formik"
import { SPACE } from "../../design/tokens/space"
import { BREAKPOINT, ETypo, getTypo, getRawTypo } from "../../design"
import Button from "../button"

export const StyledJoinForm = styled(Form)`
  background: #fff;
  box-shadow: 0 4px 16px ${transparentize(0.75, "#F69400")};
  padding: ${SPACE.SPACE48};
  border-radius: 20px;
  margin-bottom: ${SPACE.SPACE48};

  @media (max-width: ${BREAKPOINT.SMALL}) {
    padding: ${SPACE.SPACE48} ${SPACE.SPACE24};
  }
`

export const Header = styled.h2`
  ${getTypo(ETypo.FORM_TITLE)};
  text-transform: uppercase;
  margin-top: 0;
  text-align: center;

  @media (max-width: ${BREAKPOINT.MEDIUM}) {
    font-size: 18px;
  }
`

export const Row = styled.div`
  display: flex;
  margin: 0 -${SPACE.SPACE8};
  align-items: flex-start;

  @media (max-width: ${BREAKPOINT.LARGE}) {
    flex-direction: column;
    align-items: unset;
  }
`
export const Column = styled.div`
  flex: 1 1 calc(25% - ${SPACE.SPACE16});
  min-width: calc(25% - ${SPACE.SPACE16});
  margin: 0 ${SPACE.SPACE8};

  @media (max-width: ${BREAKPOINT.LARGE}) {
    margin-top: ${SPACE.SPACE8};
  }
`

export const ButtonColumn = styled.div`
  flex: 1 1 calc(20% - ${SPACE.SPACE16});
  min-width: calc(20% - ${SPACE.SPACE16});
  margin: 0 ${SPACE.SPACE8};

  @media (max-width: ${BREAKPOINT.LARGE}) {
    margin-top: ${SPACE.SPACE24};
  }
`

export const StyledButton = styled(Button)`
  margin-top: ${() => {
    // necessary to push button height of label: font-size * line-height
    const { fontSize, lineHeight } = getRawTypo(ETypo.FORM_LABEL)
    const rawFontSize = stripUnit(fontSize)

    return `${rawFontSize * (lineHeight as number)}px`
  }};
`
